<!-- 解绑 -->
<template>
  <div id="UnbundlingCard">
    <div class="main">
      <div>
        <!-- 头部地理位置和搜索框 -->
        <div class="head">
          <!-- 顶部NavBar 开始 -->
          <van-nav-bar
            :title="$t('person.unbundling')"
            :left-text="$t('main.return')"
            left-arrow
            style="background-color: rgba(255,255,255,0);"
            @click-left="onClickLeft"
          />
        </div>
      </div>
      <div class="outboxstyle">
        <!-- 个人信息 -->
        <div class="box-parent">
          <div class="box">
            <van-row>
              <van-col>
                <div
                  class="img-box"
                >
                  <img :src="topimg" style="width:40px; height:40px;" class="img">
                </div>
              </van-col>
              <van-col>
                <div class="text-box">
                  <p>{{ $t('person.account') }} <span> {{ user.accountNum }}</span></p>
                  <p>{{ $t('person.name') }} <span>{{ user.name }}</span></p>
                  <p>{{ $t('person.id') }}<span>{{ user.idserial }}</span></p>
                  <p>{{ $t('person.college') }}<span>{{ user.deptName }}</span></p>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
        <!-- bottom-title -->
        <div class="bottom-title">
          {{ $t('unbundling.bottom-title') }}
        </div>
        <!-- bottom-part -->
        <div class="bottom-part">
          <div v-for="item in list" :key="item.id" class="line-part" @click="unbundlingBtn(item.id, item.appId)">
            <div class="line-part-left">
              <img :src="item.accountType==='WECHAT'?wechat:topimg" alt="">
              <div>
                <span class="line-top">{{ item.boundName }}</span>
                <span class="line-bottom">{{ item.appId }}</span>
              </div>
            </div>
            <div class="line-part-right">
              {{ $t('unbundling.btn') }}
            </div>
          </div>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>
<script>
import { Dialog, Toast } from 'vant'
import { findUserInfoByAccountNum } from '@/api/password'
import { queryBoundUserAccount, unbindUserAccount } from '@/api/personCenter'
export default {
  name: 'UnbundlingCard',
  data() {
    return {
      topimg: require('../../assets/newImages/user.png'),
      wechat: require('../../assets/images/weixin.png'),
      list: [],
      user: {
        accountNum: '',
        name: '',
        idserial: '',
        deptName: ''
      }
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #F5F5F5')
  },
  mounted() {
    this.init()
    this.getList()
  },
  methods: {
    getList() {
      queryBoundUserAccount().then(res => {
        this.list = res.data
      })
    },
    init() {
      const accountNum = this.$store.state.user.accountNum || localStorage.getItem('accountNum')
      const schoolCode = window.localStorage.getItem('schoolcode')
      const dataSource = this.$store.state.settings.dataSource
      findUserInfoByAccountNum(accountNum, schoolCode, dataSource).then(res => {
        this.user.accountNum = res.data.accountNum
        this.user.name = res.data.name
        this.user.idserial = res.data.idserial
        this.user.deptName = res.data.deptName
      })
    },
    unbundlingBtn(id, appId) {
      Dialog.confirm({
        title: '提示',
        message: '是否解绑(' + appId + ')该账号？'
      }).then(() => {
        unbindUserAccount(id).then(res => {
          Toast.success('解绑成功')
          this.getList()
        })
      })
    },
    // 返回
    onClickLeft() {
      this.$router.push({ path: '/personCenter' })
    }
  }
}
</script>
<style scoped lang="scss">
#UnbundlingCard {
  position: relative;
  background-color: #eff6ff;
  .head {
    height: 84px;
    width: 100%;
    background:linear-gradient(135deg, #3793e5 0%, #4046a6 100%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
}
.outboxstyle {
  background-color: #F5F5F5;
  border-radius: 10px;
  margin-top: -20px;
  margin-bottom: 100px;
  width: 100%;
  height: 100%;
}
.box-parent{
    padding: 20px 16px;
    background-color: #fff;
    border-radius: 16px 16px 0px 0px;
}
.box {
    padding: 0 5%;
    height: 120px;
    background-image: url('../../assets/images/person-back.jpg');
    background-repeat:no-repeat;
    background-size:100% 100%;
    border-radius: 12px;
    display: flex;
    align-items: center;
}
.img-box {
    width: 60px;
    height: 60px;
    border: 1px solid #ffffff;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.van-row{
    display: flex;
    align-items: center;
}
.text-box {
    margin-left: 20px;
    width: 100%;
    p {
        font-size: 14px;
        height: 20px;
        line-height:20px;
        color: rgba(216, 235, 244, 1);
        span {
            color: #FFFFFF;
        }
    }
}
::v-deep .van-nav-bar .van-icon {
    color: #ffffff;
}
::v-deep .van-nav-bar__title {
    color: #ffffff;
}
::v-deep .van-nav-bar__text {
    color: #ffffff;
}
::v-deep [class*='van-hairline']::after {
    border: none;
}
.van-nav-bar {
    height: 80px;
}
.bottom-title{
    height: 50px;
    font-size: 12px;
    color: #747474;
    padding: 25px 0 0 20px;
}
// 底部主要绑定
.bottom-part{
    background-color: #fff;
    padding: 0 17px;
    font-size: 14px;
    .line-part{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 67px;
        border-bottom: 1px solid #f5f5f5;
        .line-part-left{
            display: flex;
            img{
                width: 36px;
                height: 36px;
                margin-right: 15px;
            }
            div{
                height: 36px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .line-bottom{
                    font-size: 12px;
                    color: #747474;
                }
            }
        }
        .line-part-right{
            color: #2F9FF2;
        }
    }
    .line-part:last-of-type{
        border-bottom: 0;
    }
}
</style>
